import React from 'react';
import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';

const Dashboard = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  switch(user.role.toUpperCase()) {
    case 'ADMIN':
      return <Navigate to="/admin_dashboard" replace />;
    case 'ACCOUNT_OWNER':
      return <Navigate to="/owner_dashboard" replace />;
    case 'ACCOUNT_MANAGER':
      return <Navigate to="/manager_dashboard" replace />;
    case 'ACCOUNT_USER':
      return <Navigate to="/user_dashboard" replace />;
    default:
      return <Navigate to="/unauthorized" replace />;
  }
};

export default Dashboard;
import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Typography, CircularProgress, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  Checkbox, FormGroup, FormControlLabel
} from '@mui/material';

const EmployeeReviews = ({ employee }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (employee && employee.id) {
      fetchEmployeeReviews(employee.id);
    } else {
      setLoading(false);
      setError(null);
      setReviews([]);
    }
  }, [employee]);

  const fetchEmployeeReviews = async (employeeId) => {
    try {
      setLoading(true);
      const response = await api.get(`/api/reviews/employee/${employeeId}`);
      setReviews(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching reviews:', err);
      setError('Failed to fetch reviews. Please try again.');
      setReviews([]);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      : 'Invalid Date';
  };

  const handleViewFullReview = (review) => {
    setSelectedReview(review);
    setOpenDialog(true);
  };

  const renderReviewDetail = (label, value) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2">{label}:</Typography>
      <Typography>{value || 'N/A'}</Typography>
    </Box>
  );

  const renderCheckboxGroup = (title, items) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2">{title}</Typography>
      <FormGroup>
        {items.map((item, index) => (
          <FormControlLabel
            key={index}
            control={<Checkbox checked={item.value === 1} disabled />}
            label={item.label}
          />
        ))}
      </FormGroup>
    </Box>
  );

  if (loading) return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!employee || !employee.id) return <Typography>Please select an employee to view their reviews.</Typography>;
  if (reviews.length === 0) return <Typography>No reviews found for this employee.</Typography>;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Review Date</TableCell>
              <TableCell>Manager</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Performance Score</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews.map((review) => (
              <TableRow key={review.id}>
                <TableCell>{formatDate(review.date_of_meeting)}</TableCell>
                <TableCell>{review.manager}</TableCell>
                <TableCell>{review.job_title}</TableCell>
                <TableCell>{review.performance_score}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleViewFullReview(review)}>
                    View Full Review
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Full Review Details</DialogTitle>
        <DialogContent>
          {selectedReview && (
            <Box>
              {renderReviewDetail('Employee Name', selectedReview.employee_name)}
              {renderReviewDetail('Job Title', selectedReview.job_title)}
              {renderReviewDetail('Department', selectedReview.department)}
              {renderReviewDetail('Date of Engagement', formatDate(selectedReview.date_of_engagement))}
              {renderReviewDetail('Manager', selectedReview.manager)}
              {renderReviewDetail('Date of Meeting', formatDate(selectedReview.date_of_meeting))}
              {renderReviewDetail('Objectives', selectedReview.objectives)}
              {renderReviewDetail('Strengths', selectedReview.strengths)}
              {renderReviewDetail('Needs Improvement', selectedReview.needs_improvement)}
              {renderReviewDetail('Development Activities', selectedReview.development_activities)}
              {renderReviewDetail('Career Planning', selectedReview.career_planning)}
              {renderReviewDetail('Other Discussion Points', selectedReview.other_discussion_points)}
              
              {renderCheckboxGroup('Client Service', [
                { label: 'Meets Targets', value: selectedReview.client_service_meets_targets },
                { label: 'Communicates Effectively', value: selectedReview.client_service_communicates_effectively },
                { label: 'High Quality Service', value: selectedReview.client_service_high_quality_service }
              ])}
              
              {renderCheckboxGroup('Retail Sales', [
                { label: 'Recommends Products', value: selectedReview.retail_sales_recommends_products },
                { label: 'Meets Sales Targets', value: selectedReview.retail_sales_meets_sales_targets },
                { label: 'Product Knowledge', value: selectedReview.retail_sales_product_knowledge }
              ])}
              
              {renderCheckboxGroup('Work Management', [
                { label: 'Manages Workload', value: selectedReview.work_management_manages_workload },
                { label: 'Prioritizes Tasks', value: selectedReview.work_management_prioritizes_tasks },
                { label: 'Meets Client Demand', value: selectedReview.work_management_meets_client_demand }
              ])}
              
              {renderCheckboxGroup('Teamwork', [
                { label: 'Works Cooperatively', value: selectedReview.teamwork_works_cooperatively },
                { label: 'Positive Contribution', value: selectedReview.teamwork_positive_contribution },
                { label: 'Supports Team', value: selectedReview.teamwork_supports_team }
              ])}
              
              {renderCheckboxGroup('Attendance', [
                { label: 'Consistent Attendance', value: selectedReview.attendance_consistent_attendance },
                { label: 'On Time', value: selectedReview.attendance_on_time },
                { label: 'Communicates Absences', value: selectedReview.attendance_communicates_absences }
              ])}
              
              {renderCheckboxGroup('Communication', [
                { label: 'Effective Communication', value: selectedReview.communication_effective_communication },
                { label: 'Active Listening', value: selectedReview.communication_active_listening },
                { label: 'Clear Explanation', value: selectedReview.communication_clear_explanation }
              ])}
              
              {renderCheckboxGroup('Overall Performance', [
                { label: 'Outstanding', value: selectedReview.overall_performance_outstanding },
                { label: 'Meets Expectations', value: selectedReview.overall_performance_meets_expectations },
                { label: 'Needs Improvement', value: selectedReview.overall_performance_needs_improvement },
                { label: 'Unsatisfactory', value: selectedReview.overall_performance_unsatisfactory }
              ])}
              
              {renderReviewDetail('Supervisor Comments', selectedReview.supervisor_comments)}
              {renderReviewDetail('Employee Comments', selectedReview.employee_comments)}
              {renderReviewDetail('Action Plans', selectedReview.action_plans)}
              {renderReviewDetail('Performance Score', selectedReview.performance_score)}
              {renderReviewDetail('Comments', selectedReview.comments)}
              {renderReviewDetail('Status', selectedReview.status)}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmployeeReviews;
import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { useAuth } from './AuthContext'; // Assuming you have an AuthContext

const SubmitHolidayRequest = ({ onSubmit }) => {
  const { user } = useAuth(); // Get the current user from AuthContext
  const [formData, setFormData] = useState({
    employeeName: '',
    employeeId: '',
    dateOfRequest: new Date().toISOString().split('T')[0],
    startDate: '',
    endDate: '',
    numberOfDaysHoliday: '',
    notes: ''
  });
  const [employees, setEmployees] = useState([]);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin = user.role === 'ACCOUNT_OWNER' || user.role === 'ACCOUNT_MANAGER';

  useEffect(() => {
    if (isAdmin) {
      fetchEmployees();
    } else {
      setFormData(prev => ({
        ...prev,
        employeeName: user.name,
        employeeId: user.employeeId
      }));
    }
  }, [isAdmin, user]);

  const fetchEmployees = async () => {
    try {
      const response = await api.get('/api/employees');
      const uniqueEmployees = Array.from(new Set(response.data.map(emp => emp.name)))
        .sort((a, b) => a.localeCompare(b))
        .map(name => ({
          id: response.data.find(emp => emp.name === name).id,
          name: name
        }));
      setEmployees(uniqueEmployees);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setMessage({ text: 'Error fetching employees', type: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const updatedData = { ...prev, [name]: value };
      
      if (name === 'employeeName' && isAdmin) {
        const selectedEmployee = employees.find(emp => emp.name === value);
        updatedData.employeeId = selectedEmployee ? selectedEmployee.id : '';
      }
      
      if (name === 'startDate' || name === 'endDate') {
        if (updatedData.startDate && updatedData.endDate) {
          const start = new Date(updatedData.startDate);
          const end = new Date(updatedData.endDate);
          const diffTime = Math.abs(end - start);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
          updatedData.numberOfDaysHoliday = diffDays;
        }
      }
      
      return updatedData;
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  try {
    const requestData = {
      employeeId: parseInt(formData.employeeId),
      dateOfRequest: formData.dateOfRequest,
      startDate: formData.startDate,
      endDate: formData.endDate,
      numberOfDaysHoliday: parseInt(formData.numberOfDaysHoliday),
      notes: formData.notes
    };
    console.log('Submitting holiday request:', requestData);

    const response = await api.post('/api/holidays', requestData);

    console.log('Response:', response);
    if (response.status === 201) {
      setMessage({ text: 'Holiday request submitted successfully', type: 'success' });
      if (onSubmit) onSubmit();
    } else {
      throw new Error('Unexpected response status');
    }
  } catch (error) {
    console.error('Error submitting holiday request:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      setMessage({ text: `Error: ${error.response.data.message || 'An unexpected error occurred'}`, type: 'error' });
    } else {
      setMessage({ text: 'An unexpected error occurred', type: 'error' });
    }
  } finally {
    setIsLoading(false);
  }
};

  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      <h2>Submit Holiday Request</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="employeeName">Employee Name:</label>
          {isAdmin ? (
            <select
              id="employeeName"
              name="employeeName"
              value={formData.employeeName}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '5px' }}
            >
              <option value="">Select an employee</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.name}>
                  {employee.name}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              id="employeeName"
              name="employeeName"
              value={formData.employeeName}
              readOnly
              style={{ width: '100%', padding: '5px' }}
            />
          )}
        </div>
        <div>
          <label htmlFor="dateOfRequest">Date of Request:</label>
          <input
            type="date"
            id="dateOfRequest"
            name="dateOfRequest"
            value={formData.dateOfRequest}
            onChange={handleChange}
            required
            style={{ width: '100%', padding: '5px' }}
          />
        </div>
        <div>
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            required
            style={{ width: '100%', padding: '5px' }}
          />
        </div>
        <div>
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            required
            style={{ width: '100%', padding: '5px' }}
          />
        </div>
        <div>
          <label htmlFor="numberOfDaysHoliday">Number of Days Holiday:</label>
          <input
            type="number"
            id="numberOfDaysHoliday"
            name="numberOfDaysHoliday"
            value={formData.numberOfDaysHoliday}
            onChange={handleChange}
            required
            readOnly
            style={{ width: '100%', padding: '5px' }}
          />
        </div>
        <div>
          <label htmlFor="notes">Notes:</label>
          <textarea
            id="notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            style={{ width: '100%', padding: '5px' }}
          />
        </div>
        <button 
          type="submit" 
          style={{ 
            padding: '10px', 
            backgroundColor: '#007bff', 
            color: 'white', 
            border: 'none', 
            cursor: 'pointer',
            opacity: isLoading ? 0.7 : 1
          }}
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit Request'}
        </button>
      </form>
      {message.text && (
        <div style={{ 
          marginTop: '20px', 
          padding: '10px', 
          backgroundColor: message.type === 'error' ? '#ffcccc' : '#ccffcc',
          border: `1px solid ${message.type === 'error' ? '#ff0000' : '#00ff00'}`,
          borderRadius: '5px'
        }}>
          {message.text}
        </div>
      )}
    </div>
  );
};

export default SubmitHolidayRequest;
import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Box, Paper, Typography, CircularProgress, Alert } from '@mui/material';
import DatePickerComponent from './DatePickerComponent';
import GaugeChart from 'react-gauge-chart';
import './OwnerDashboard.css'; // You can rename this CSS file to a more generic name like Dashboard.css if it's shared between components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAuth } from './AuthContext';
import dayjs from 'dayjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const KPIBox = ({ title, value, growth }) => (
  <div className="kpi-box">
    <Typography variant="subtitle1">{title}</Typography>
    <Typography variant="h5">{value}</Typography>
    <Typography variant="body2" color={growth >= 0 ? "success.main" : "error.main"}>
      {growth}%
    </Typography>
  </div>
);

const ManagerDashboard = () => {
  const { user, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [revenueData, setRevenueData] = useState(null);
  const [annualSales, setAnnualSales] = useState(0);
  const [kpiData, setKpiData] = useState({
    profit: { value: 0, growth: 0 },
    sales: { value: 0, growth: 0 },
    payments: { value: 0, growth: 0 },
    transactions: { value: 0, growth: 0 }
  });
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  useEffect(() => {
    if (isAuthenticated) {
      fetchDashboardData();
    }
  }, [isAuthenticated, startDate, endDate]);

  const fetchDashboardData = async () => {
    try {
      const [revenueResponse, kpiResponse] = await Promise.all([
        api.get('/api/revenue_data', { params: { start_date: startDate.format('YYYY-MM-DD'), end_date: endDate.format('YYYY-MM-DD') } }),
        api.get('/api/kpi_data', { params: { start_date: startDate.format('YYYY-MM-DD'), end_date: endDate.format('YYYY-MM-DD') } })
      ]);

      const revenue = revenueResponse.data;
      setRevenueData(revenue.monthlyData);
      setAnnualSales(revenue.annualTotal);
      
      setKpiData(kpiResponse.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard data:', error.response?.data || error.message);
      setError('Failed to load dashboard data. Please try again later.');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const barChartData = {
    labels: revenueData ? revenueData.map(item => item.month) : [],
    datasets: [
      {
        label: 'Monthly Revenue',
        data: revenueData ? revenueData.map(item => item.total) : [],
        backgroundColor: 'rgba(75,192,192,1)',
      }
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Revenue (£)'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  // Define the permission required to view each KPI
  const permissionMap = {
    'Profit': 'view_profit',
    'Annual Sales': 'view_sales',
    'Payments': 'view_payments',
    'Transactions': 'view_transactions'
  };

  return (
    <Box className="dashboard-container">
      <Typography variant="h4" gutterBottom>
        Manager Dashboard
      </Typography>
      <DatePickerComponent startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 3 }}>
        <GaugeChart id="revenue-gauge" 
          nrOfLevels={10} 
          percent={annualSales / 100000} 
          textColor="#000000"
          needleColor="#345243"
          colors={["#FF5F6D", "#FFC371"]}
          arcWidth={0.3}
        />
        <GaugeChart id="profit-gauge" 
          nrOfLevels={10} 
          percent={(annualSales - (kpiData.expenditure?.value || 0)) / 50000} 
          textColor="#000000"
          needleColor="#345243"
          colors={["#FF5F6D", "#FFC371"]}
          arcWidth={0.3}
        />
      </Box>
      <Paper className="chart-container">
        <Typography variant="h6" gutterBottom>
          Monthly Revenue
        </Typography>
        {revenueData && <Bar data={barChartData} options={barOptions} />}
      </Paper>
      <Box className="kpi-grid">
        {Object.keys(permissionMap).map((key) => (
          user.permissions?.[permissionMap[key]] && (
            <KPIBox
              key={key}
              title={key}
              value={`£${kpiData[key.toLowerCase()].value}`}
              growth={kpiData[key.toLowerCase()].growth}
            />
          )
        ))}
      </Box>
    </Box>
  );
};

export default ManagerDashboard;

import React, { useState } from 'react';
import api from './axiosConfig';
import { TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DatePicker from '@mui/lab/DatePicker';
import './employee_forms.css';

const SubmitTraining = ({ employeeId, onSubmitSuccess, isManager }) => {
  const [trainingData, setTrainingData] = useState({
    date_of_training: '',
    training_provider: '',
    internal_external: 'Internal',
    certification_qualification: 'Certification',
    renewal_date: '',
    cost: '',
    training_paid_by: 'Employer',
    recoupment: false,
    recoupment_period: '',
    notes: '',
    reminder_date: null
  });

  const [file, setFile] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTrainingData({ ...trainingData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDateChange = (date) => {
    setTrainingData({ ...trainingData, reminder_date: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('employeeId', employeeId);
    for (const key in trainingData) {
      formData.append(key, trainingData[key]);
    }
    if (file) {
      formData.append('file', file);
    }

    try {
      await api.post(`/api/employees/${employeeId}/training`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      setSnackbar({ open: true, message: 'Training data submitted successfully!', severity: 'success' });
      onSubmitSuccess();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting training data.', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ 
        mt: 3, 
        maxWidth: 1200, // Set max width to 800px or whatever suits your design
        mx: 'auto', // Center the form horizontally
        p: 2, // Optional padding
        border: '1px solid #ccc', // Border width and color
        borderRadius: 2, // Rounded corners, adjust as needed
        boxShadow: 1 // Optional shadow for better visibility
      }}
    >
      <Typography variant="h6" gutterBottom>
        Submit Training
      </Typography>
      <TextField
        fullWidth
        label="Date of Training"
        type="date"
        name="date_of_training"
        value={trainingData.date_of_training}
        onChange={handleChange}
        sx={{ mb: 2 }}
        InputLabelProps={{ shrink: true }}
        required
      />
      <TextField
        fullWidth
        label="Training Provider"
        name="training_provider"
        value={trainingData.training_provider}
        onChange={handleChange}
        sx={{ mb: 2 }}
        required
      />
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend">Internal/External</FormLabel>
        <RadioGroup
          name="internal_external"
          value={trainingData.internal_external}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="Internal" control={<Radio />} label="Internal" />
          <FormControlLabel value="External" control={<Radio />} label="External" />
        </RadioGroup>
      </FormControl>
      <TextField
        fullWidth
        label="Certification/Qualification"
        name="certification_qualification"
        value={trainingData.certification_qualification}
        onChange={handleChange}
        sx={{ mb: 2 }}
        required
      />
      <TextField
        fullWidth
        label="Renewal Date"
        type="date"
        name="renewal_date"
        value={trainingData.renewal_date}
        onChange={handleChange}
        sx={{ mb: 2 }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        label="Cost"
        type="number"
        name="cost"
        value={trainingData.cost}
        onChange={handleChange}
        sx={{ mb: 2 }}
        required
      />
      <TextField
        fullWidth
        label="Training Paid By"
        name="training_paid_by"
        value={trainingData.training_paid_by}
        onChange={handleChange}
        sx={{ mb: 2 }}
        required
      />
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend">Recoupment</FormLabel>
        <RadioGroup
          name="recoupment"
          value={trainingData.recoupment ? 'yes' : 'no'}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      {trainingData.recoupment && (
        <TextField
          fullWidth
          label="Recoupment Period"
          name="recoupment_period"
          value={trainingData.recoupment_period}
          onChange={handleChange}
          sx={{ mb: 2 }}
          required
        />
      )}
      <TextField
        fullWidth
        label="Notes"
        name="notes"
        value={trainingData.notes}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <Box sx={{ mb: 2 }}>
        <input
          type="file"
          name="file"
          accept=".pdf,.jpg,.jpeg"
          onChange={handleFileChange}
        />
      </Box>
      {isManager && (
        <Box sx={{ mb: 2 }}>
          <DatePicker
            label="Set Reminder for Refresher Training"
            value={trainingData.reminder_date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Box>
      )}
      <Button variant="contained" color="primary" type="submit">
        Submit Training
      </Button>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default SubmitTraining;

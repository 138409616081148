import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Box, 
  Grid, 
  Typography, 
  TextField, 
  Button, 
  Tabs, 
  Tab, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress
} from '@mui/material';
import RenterSearch from './RenterSearch';
import './styles.css';

const SpaceRentalCard = ({ userRole }) => {
  console.log('Rendering SpaceRentalCard');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rentalData, setRentalData] = useState({
    name: '',
    address1: '',
    address2: '',
    address3: '',
    postcode: '',
    phoneNumber: '',
    emergencyContactName: '',
    emergencyContactNumber: '',
    rentPeriod: '',
    rentCost: '',
    turnoverRentPercentage: '',
    dateRentalCommenced: '',
    invoiceOffset: ''
  });
  const [activeTab, setActiveTab] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    console.log('SpaceRentalCard mounted');
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchRentalData(), fetchDocuments(), fetchInvoices()]);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchRentalData = async () => {
    console.log('Fetching rental data');
    try {
      const response = await api.get('/api/rentals/data');
      setRentalData(response.data);
    } catch (error) {
      console.error('Error fetching rental data:', error);
      setRentalData({
        name: '',
        address1: '',
        address2: '',
        address3: '',
        postcode: '',
        phoneNumber: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        rentPeriod: '',
        rentCost: '',
        turnoverRentPercentage: '',
        dateRentalCommenced: '',
        invoiceOffset: ''
      });
    }
  };

  const fetchDocuments = async () => {
    console.log('Fetching documents');
    try {
      const response = await api.get('/api/rentals/documents');
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setDocuments([]);
    }
  };

  const fetchInvoices = async () => {
    console.log('Fetching invoices');
    try {
      const response = await api.get('/api/rentals/invoices');
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
      setInvoices([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRentalData({ ...rentalData, [name]: value });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    
    if (!allowedTypes.includes(file.type)) {
      alert('This file type is not allowed. Please upload a PDF, JPEG, PNG, or Word document.');
      e.target.value = '';
      return;
    }
  
    setSelectedFile(file);
  
    const formData = new FormData();
    formData.append('document', file);
  
    try {
      const response = await api.post('/api/rentals/upload_document', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setDocuments([...documents, response.data]);
    } catch (error) {
      console.error('Error uploading document:', error);
      alert('Error uploading document. Please try again.');
    }
  };
  
  const handleSave = async () => {
    try {
      await api.post('/api/rentals/save', rentalData);
      alert('Rental data saved successfully');
    } catch (error) {
      console.error('Error saving rental data:', error);
      alert('Error saving rental data. Please try again.');
    }
  };
  
  const handleDownloadInvoice = (invoiceId) => {
    window.open(`/api/rentals/invoice/${invoiceId}`, '_blank');
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'accepted':
        return 'success';
      case 'denied':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  if (error) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><Typography color="error">Error: {error}</Typography></Box>;

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Space Rental Card</Typography>
      
      <RenterSearch />

      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
        <Tab label="Rental Info" />
        <Tab label="Documents" />
        <Tab label="Invoices" />
      </Tabs>

      {activeTab === 0 && (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={rentalData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="address1"
                value={rentalData.address1}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="address2"
                value={rentalData.address2}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Address Line 3"
                name="address3"
                value={rentalData.address3}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postcode"
                name="postcode"
                value={rentalData.postcode}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={rentalData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Emergency Contact Name"
                name="emergencyContactName"
                value={rentalData.emergencyContactName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Emergency Contact Number"
                name="emergencyContactNumber"
                value={rentalData.emergencyContactNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Rent Period"
                name="rentPeriod"
                value={rentalData.rentPeriod}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Rent Cost"
                name="rentCost"
                type="number"
                value={rentalData.rentCost}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Turnover Rent %"
                name="turnoverRentPercentage"
                type="number"
                value={rentalData.turnoverRentPercentage}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date Rental Commenced"
                name="dateRentalCommenced"
                type="date"
                value={rentalData.dateRentalCommenced}
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Invoice Offset (days)"
                name="invoiceOffset"
                type="number"
                value={rentalData.invoiceOffset}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Button variant="contained" onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>Documents</Typography>
          
          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell>Reminder Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>{doc.name}</TableCell>
                    <TableCell>{new Date(doc.uploadDate).toLocaleDateString()}</TableCell>
                    <TableCell>{doc.reminderDate ? new Date(doc.reminderDate).toLocaleDateString() : 'N/A'}</TableCell>
                    <TableCell>
                      <Chip 
                        label={doc.status} 
                        color={getStatusColor(doc.status)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 2 }}>
            <Button variant="contained" component="label">
              Upload Document
              <input 
                type="file" 
                hidden 
                onChange={handleFileUpload} 
                accept=".pdf,.jpeg,.jpg,.png,.doc,.docx"
              />
            </Button>
          </Box>
        </Box>
      )}

      {activeTab === 2 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Invoices</Typography>
          <Grid container spacing={2}>
            {invoices.map((invoice, index) => (
              <Grid item xs={12} key={index}>
                <Paper sx={{ p: 2 }}>
                  <Typography>Invoice ID: {invoice.id}</Typography>
                  <Typography>Status: {invoice.status}</Typography>
                  <Button variant="outlined" onClick={() => handleDownloadInvoice(invoice.id)}>Print Invoice</Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

export default SpaceRentalCard;
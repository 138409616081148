import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Box, Grid, Paper, Typography, CircularProgress, Alert, TextField, Button, Snackbar } from '@mui/material';
import KPIBox from './KPIBox';

const UserDashboard = () => {
  const [holidayData, setHolidayData] = useState({
    balance: 0,
    totalDays: 0,
    takenDays: 0,
    pendingDays: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    employeeName: '',
    dateOfRequest: new Date().toISOString().split('T')[0],
    startDate: '',
    endDate: '',
    numberOfDaysHoliday: '',
    notes: ''
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetchDashboardData();
    fetchEmployees();
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [holidayResponse, requestsResponse] = await Promise.all([
        api.get('/user/holiday-balance'),
        api.get('/user/pending-requests')
      ]);
      setHolidayData({
        ...holidayResponse.data,
        pendingDays: requestsResponse.data.pending_requests
      });
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await api.get('/employees');
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
      showSnackbar('Error fetching employees', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'startDate' || name === 'endDate') {
      if (formData.startDate && formData.endDate) {
        const start = new Date(formData.startDate);
        const end = new Date(formData.endDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        setFormData(prev => ({ ...prev, numberOfDaysHoliday: diffDays }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const employee = employees.find(emp => emp.name === formData.employeeName);
      if (!employee) {
        showSnackbar('Employee not found', 'error');
        return;
      }

      await api.post('/submit_holiday', {
        employeeId: employee.id,
        dateOfRequest: formData.dateOfRequest,
        startDate: formData.startDate,
        endDate: formData.endDate,
        numberOfDaysHoliday: formData.numberOfDaysHoliday,
        notes: formData.notes
      });

      showSnackbar('Holiday request submitted successfully', 'success');
      resetForm();
      fetchDashboardData(); // Refresh dashboard data after submitting
    } catch (error) {
      console.error('Error submitting holiday request:', error);
      showSnackbar('Error submitting holiday request', 'error');
    }
  };

  const resetForm = () => {
    setFormData({
      employeeName: '',
      dateOfRequest: new Date().toISOString().split('T')[0],
      startDate: '',
      endDate: '',
      numberOfDaysHoliday: '',
      notes: ''
    });
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          User Dashboard
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Holiday Balance" value={`${holidayData.balance} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Total Days" value={`${holidayData.totalDays} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Taken Days" value={`${holidayData.takenDays} days`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <KPIBox title="Pending Days" value={`${holidayData.pendingDays} days`} />
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Submit Holiday Request
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Employee Name"
                      name="employeeName"
                      value={formData.employeeName}
                      onChange={handleChange}
                      variant="outlined"
                      required
                      select
                      SelectProps={{ native: true }}
                    >
                      <option value="">Select an employee</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.name}>
                          {employee.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Date of Request"
                      name="dateOfRequest"
                      value={formData.dateOfRequest}
                      onChange={handleChange}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Start Date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="End Date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Number of Days Holiday"
                      name="numberOfDaysHoliday"
                      value={formData.numberOfDaysHoliday}
                      onChange={handleChange}
                      variant="outlined"
                      required
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Notes"
                      name="notes"
                      value={formData.notes}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                      Submit Request
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserDashboard;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth } from './AuthContext';

const KPIBox = ({ title, value, growth }) => {
  const { user } = useAuth();

  // Define the permission required to view each KPI
  const permissionMap = {
    'Profit': 'view_profit',
    'Annual Sales': 'view_sales',
    'Payments': 'view_payments',
    'Transactions': 'view_transactions'
  };

  const permissionRequired = permissionMap[title];

  // Check if the user is an account owner or has the required permission
  const hasPermission = user?.role === 'ACCOUNT_OWNER' || user?.permissions?.[permissionRequired];

  if (!hasPermission) {
    return null; // Render nothing if the user doesn't have permission
  }

  return (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h4">{value}</Typography>
      <Typography variant="subtitle1">{growth}%</Typography>
    </Box>
  );
};

export default KPIBox;

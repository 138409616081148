import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Grid, Paper, Typography, Container, CircularProgress, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button } from '@mui/material';
import { useAuth } from './AuthContext';

const AdminDashboard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [users, setUsers] = useState([]);
  const [vatRates, setVatRates] = useState({
    standard_rate: '',
    first_year_rate: '',
    following_year_rate: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dashboardResponse, usersResponse] = await Promise.all([
          api.get('/admin/dashboard'),
          api.get('/admin/users')
        ]);
        setDashboardData(dashboardResponse.data);
        setUsers(usersResponse.data);
        setVatRates(dashboardResponse.data.vat_rates);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching admin data:', error);
        setError('Failed to load admin data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleVatRateChange = (event) => {
    setVatRates({
      ...vatRates,
      [event.target.name]: event.target.value
    });
  };

  const handleVatRateSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post('/admin/vat-rates', vatRates);
      alert('VAT rates updated successfully');
    } catch (error) {
      console.error('Error updating VAT rates:', error);
      alert('Failed to update VAT rates');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Total Users</Typography>
            <Typography variant="h4">{dashboardData?.total_users}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>VAT Rates</Typography>
            <form onSubmit={handleVatRateSubmit}>
              <TextField
                label="Standard Rate"
                name="standard_rate"
                value={vatRates.standard_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <TextField
                label="First Year Rate"
                name="first_year_rate"
                value={vatRates.first_year_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Following Year Rate"
                name="following_year_rate"
                value={vatRates.following_year_rate}
                onChange={handleVatRateChange}
                type="number"
                fullWidth
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary">
                Update VAT Rates
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>User List</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
import React from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material';

const Expenditure = ({ formik }) => (
  <Grid container spacing={2}>
    {[
      { name: 'date', label: 'Date', type: 'date', required: true },
      { name: 'type', label: 'Type', required: true },
      { name: 'supplier_name', label: 'Supplier Name', required: true },
      { name: 'category', label: 'Category', required: true },
      { name: 'description', label: 'Description', required: true },
      { name: 'total', label: 'Total', type: 'number', required: true },
      { name: 'vat', label: 'VAT', type: 'number', required: true },
      { name: 'net_expenditure', label: 'Net Expenditure', type: 'number', required: true }
    ].map((field) => (
      <Grid item xs={12} sm={6} key={field.name}>
        <TextField
          fullWidth
          id={field.name}
          name={field.name}
          label={field.label}
          type={field.type || 'text'}
          value={formik.values[field.name]}
          onChange={formik.handleChange}
          error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
          helperText={formik.touched[field.name] && formik.errors[field.name]}
          required={field.required}
        />
      </Grid>
    ))}
    <Grid item xs={12}>
      <RadioGroup
        row
        name="paymentType"
        value={formik.values.paymentType}
        onChange={formik.handleChange}
      >
        <FormControlLabel value="cash" control={<Radio />} label="Cash" />
        <FormControlLabel value="card" control={<Radio />} label="Card" />
        <FormControlLabel value="bank" control={<Radio />} label="Bank Payment" />
      </RadioGroup>
    </Grid>
  </Grid>
);

export default Expenditure;
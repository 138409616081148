// RenterSearch.js

import React, { useState } from 'react';
import { TextField, Button, Box, List, ListItem, ListItemText } from '@mui/material';
import api from './axiosConfig';

const RenterSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await api.get(`/renters/search?term=${searchTerm}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching renters:', error);
      alert('Error searching renters. Please try again.');
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <TextField
        label="Find Renters"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        variant="outlined"
        sx={{ mb: 1 }}
      />
      <Button variant="contained" onClick={handleSearch}>Search</Button>
      {searchResults.length > 0 && (
        <List>
          {searchResults.map((renter) => (
            <ListItem key={renter.id}>
              <ListItemText primary={renter.name} secondary={renter.address1} />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default RenterSearch;
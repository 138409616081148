import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Container, Box, Grid, Paper, Typography, TextField, MenuItem, FormControlLabel, Checkbox, Button } from '@mui/material';

const SubmitAbsence = () => {
  const [employees, setEmployees] = useState([]);
  const [formData, setFormData] = useState({
    employeeId: '',
    date: '',
    reason: '',
    fitNoteReceived: false,
    selfCertificationForm: false,
    returnToWorkDate: '',
    numberOfDaysAbsent: 0,
    backToWorkInterviewCompleted: false,
    notes: '',
    accountId: 1  // Assuming this is needed and comes from your app logic
  });

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await api.get('/employees');
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/absences/submit', formData);
      console.log('Absence data submitted:', response.data);
      // Here you might want to show a success message or redirect the user
    } catch (error) {
      console.error('Error submitting absence data:', error);
      // Here you might want to show an error message to the user
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <Box 
        sx={{ 
          mt: 3, 
          maxWidth: 1200,
          mx: 'auto',
          p: 2,
          border: '1px solid #ccc',
          borderRadius: 2,
          boxShadow: 1
        }}
      >
        <Typography variant="h4" gutterBottom>
          Submit Absence
        </Typography>
        <Paper sx={{ p: 3 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Employee"
                  name="employeeId"
                  value={formData.employeeId}
                  onChange={handleChange}
                  variant="outlined"
                  required
                >
                  {employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Reason"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.fitNoteReceived}
                      onChange={handleCheckboxChange}
                      name="fitNoteReceived"
                    />
                  }
                  label="Fit Note Received"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.selfCertificationForm}
                      onChange={handleCheckboxChange}
                      name="selfCertificationForm"
                    />
                  }
                  label="Self Certification Form"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Return to Work Date"
                  name="returnToWorkDate"
                  value={formData.returnToWorkDate}
                  onChange={handleChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Number of Days Absent"
                  name="numberOfDaysAbsent"
                  value={formData.numberOfDaysAbsent}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.backToWorkInterviewCompleted}
                      onChange={handleCheckboxChange}
                      name="backToWorkInterviewCompleted"
                    />
                  }
                  label="Back to Work Interview Completed"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Notes"
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default SubmitAbsence;
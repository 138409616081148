import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import api from './axiosConfig';
import './employee_card.css';
import DOMPurify from 'dompurify';

const schema = yup.object().shape({
  position: yup.string().required('Position is required'),
  surname: yup.string().required('Surname is required'),
  firstName: yup.string().required('First name is required'),
  address: yup.string().required('Address is required'),
  privateTel: yup.string().required('Private telephone number is required'),
  businessTel: yup.string(),
  dateOfBirth: yup.date().required('Date of birth is required').max(new Date(), 'Date of birth cannot be in the future'),
  nextOfKin: yup.string(),
  nationalInsurance: yup.string().required('National Insurance number is required'),
  fullDrivingLicence: yup.boolean(),
  bankAccount: yup.boolean(),
  endorsements: yup.boolean(),
  euPassport: yup.boolean(),
  euPassportDates: yup.string().when('euPassport', {
    is: true,
    then: yup.string().required('EU passport dates are required when you have an EU passport')
  }),
  availabilityLimitations: yup.string(),
  restrictionsCovenants: yup.string(),
  willingOvertimeWeekends: yup.boolean(),
  hoursNotWishToWork: yup.string(),
  convictions: yup.string(),
  workedBefore: yup.boolean(),
  relatedToEmployee: yup.boolean(),
  relationDetails: yup.string().when('relatedToEmployee', {
    is: true,
    then: yup.string().required('Relation details are required when related to an employee')
  }),
  appliedBefore: yup.boolean(),
  workPermitNeeded: yup.boolean(),
  noticePeriod: yup.string(),
  educationSchools: yup.string(),
  educationCollegeUniversity: yup.string(),
  educationFurtherTraining: yup.string(),
  jobRelatedTraining: yup.string(),
  technicalProfessionalMemberships: yup.string(),
  foreignLanguages: yup.string(),
  pastEmployment: yup.string(),
  currentEmployer: yup.string(),
  currentEmployerAddress: yup.string(),
  currentEmployerTel: yup.string(),
  currentEmployerBusiness: yup.string(),
  jobTitle: yup.string(),
  jobDuties: yup.string(),
  reasonForLeaving: yup.string(),
  lengthOfServiceFrom: yup.date(),
  lengthOfServiceTo: yup.date().min(yup.ref('lengthOfServiceFrom'), 'End date must be after start date'),
  interestsAchievements: yup.string(),
  supplementaryInfo: yup.string(),
  declarationSigned: yup.boolean().oneOf([true], 'You must sign the declaration'),
  declarationDate: yup.date().required('Declaration date is required'),
  reference1Name: yup.string().required('Reference 1 name is required'),
  reference1Position: yup.string().required('Reference 1 position is required'),
  reference1Address: yup.string().required('Reference 1 address is required'),
  reference1Tel: yup.string().required('Reference 1 telephone number is required'),
  reference2Name: yup.string().required('Reference 2 name is required'),
  reference2Position: yup.string().required('Reference 2 position is required'),
  reference2Address: yup.string().required('Reference 2 address is required'),
  reference2Tel: yup.string().required('Reference 2 telephone number is required'),
  canApproachCurrentEmployer: yup.boolean(),
  sourceOfApplication: yup.string().required('Source of application is required'),
});

function EmploymentApplicationForm() {
  const [activeTab, setActiveTab] = useState('personal');
  const { control, handleSubmit, formState: { errors }, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const onSubmit = async (data) => {
    try {
      const response = await api.post('/api/applications', data);
      console.log('Application submitted successfully:', response.data);
      // Handle success (e.g., show success message, reset form, redirect)
    } catch (error) {
      console.error('Error submitting application:', error);
      // Handle error (e.g., show error message)
    }
  };

  const validateTabFields = async (tabName) => {
    const fieldsToValidate = {
      personal: ['position', 'surname', 'firstName', 'address', 'privateTel', 'dateOfBirth', 'nationalInsurance'],
      education: ['educationSchools', 'educationCollegeUniversity', 'educationFurtherTraining'],
      employment: ['pastEmployment', 'currentEmployer', 'jobTitle'],
      references: ['reference1Name', 'reference1Position', 'reference1Address', 'reference1Tel', 'reference2Name', 'reference2Position', 'reference2Address', 'reference2Tel', 'declarationSigned', 'declarationDate']
    };

    const result = await trigger(fieldsToValidate[tabName]);
    return result;
  };

  const changeTab = async (newTab) => {
    const isValid = await validateTabFields(activeTab);
    if (isValid) {
      setActiveTab(newTab);
    } else {
      alert('Please fill in all required fields before moving to the next tab.');
    }
  };

  const renderField = (name, label, type = 'text', options = {}, shouldRender = true) => (
    shouldRender ? (
      <div className="form-row">
        <label htmlFor={name}>{label}</label>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }) => {
            const sanitizedValue = DOMPurify.sanitize(field.value);
            if (type === 'checkbox') {
              return <input type="checkbox" {...field} checked={field.value} />;
            } else if (type === 'textarea') {
              return <textarea {...field} value={sanitizedValue} />;
            } else {
              return <input type={type} {...field} value={sanitizedValue} />;
            }
          }}
        />
        {errors[name] && <span className="error">{errors[name].message.replace(/must be a 'boolean' type, but the final value was: ''./g, 'This field is required.')}</span>}
      </div>
    ) : null
  );

  return (
    <div className="employee-card-container">
      <h1>Employment Application Form</h1>
      
      <div className="search-and-tabs">
        <div className="search-container">
          <input type="text" placeholder="Search applications" />
          <button className="search-button">SEARCH</button>
        </div>
        <div className="mode-selector">
          <label>Mode:</label>
          <select>
            <option value="new">New</option>
            <option value="view">View</option>
            <option value="edit">Edit</option>
          </select>
        </div>
      </div>

      <div className="tabs-container">
        <button className={`tablinks ${activeTab === 'personal' ? 'active' : ''}`} onClick={() => changeTab('personal')}>Personal Info</button>
        <button className={`tablinks ${activeTab === 'education' ? 'active' : ''}`} onClick={() => changeTab('education')}>Education</button>
        <button className={`tablinks ${activeTab === 'employment' ? 'active' : ''}`} onClick={() => changeTab('employment')}>Employment</button>
        <button className={`tablinks ${activeTab === 'references' ? 'active' : ''}`} onClick={() => changeTab('references')}>References</button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form-container">
        {activeTab === 'personal' && (
          <>
            {renderField('position', 'Position Applied For')}
            {renderField('surname', 'Surname')}
            {renderField('firstName', 'First Name(s)')}
            {renderField('address', 'Address', 'textarea')}
            {renderField('privateTel', 'Private Tel. No')}
            {renderField('businessTel', 'Business Tel. No')}
            {renderField('dateOfBirth', 'Date of Birth', 'date')}
            {renderField('nextOfKin', 'Next of Kin')}
            {renderField('nationalInsurance', 'National Insurance No.')}
            {renderField('fullDrivingLicence', 'Full Driving Licence', 'checkbox')}
            {renderField('bankAccount', 'Do you have a Bank Account?', 'checkbox')}
            {renderField('endorsements', 'Endorsements', 'checkbox')}
            {renderField('euPassport', 'Do you hold an EU passport?', 'checkbox')}
            {renderField('euPassportDates', 'If YES, give dates')}
            {renderField('availabilityLimitations', 'Availability Limitations', 'textarea')}
            {renderField('restrictionsCovenants', 'Restrictions or Covenants', 'textarea')}
            {renderField('willingOvertimeWeekends', 'Willing to Work Overtime and Weekends', 'checkbox')}
            {renderField('hoursNotWishToWork', 'Hours You Would Not Wish to Work')}
            {renderField('convictions', 'Convictions (other than spent convictions)', 'textarea')}
            {renderField('workedBefore', 'Have you ever worked for this business before?', 'checkbox')}
            {renderField('relatedToEmployee', 'Are you related to any person employed by this business?', 'checkbox')}
            {renderField('relationDetails', 'If YES, please give full details', 'textarea')}
            {renderField('appliedBefore', 'Have you applied for employment with this business before?', 'checkbox')}
            {renderField('workPermitNeeded', 'Do you need a work permit to take up employment in the UK?', 'checkbox')}
            {renderField('noticePeriod', 'Notice Period Required')}
          </>
        )}

{activeTab === 'education' && (
          <>
            {renderField('educationSchools', 'Schools Attended Since Age 11', 'textarea')}
            {renderField('educationCollegeUniversity', 'College or University', 'textarea')}
            {renderField('educationFurtherTraining', 'Further Formal Training', 'textarea')}
            {renderField('jobRelatedTraining', 'Job Related Training Courses', 'textarea')}
            {renderField('technicalProfessionalMemberships', 'Technical/Professional Memberships', 'textarea')}
            {renderField('foreignLanguages', 'Foreign Languages Spoken and Level', 'textarea')}
          </>
        )}

        {activeTab === 'employment' && (
          <>
            {renderField('pastEmployment', 'Past Employment', 'textarea')}
            {renderField('currentEmployer', 'Current or Last Employer')}
            {renderField('currentEmployerAddress', 'Address of Current or Last Employer', 'textarea')}
            {renderField('currentEmployerTel', 'Telephone No. of Current or Last Employer')}
            {renderField('currentEmployerBusiness', 'Nature of Business')}
            {renderField('jobTitle', 'Job Title')}
            {renderField('jobDuties', 'Brief Description of Duties', 'textarea')}
            {renderField('reasonForLeaving', 'Reason for Leaving', 'textarea')}
            {renderField('lengthOfServiceFrom', 'Length of Service (From)', 'date')}
            {renderField('lengthOfServiceTo', 'Length of Service (To)', 'date')}
            {renderField('interestsAchievements', 'Interests, Achievements, Leisure Activities', 'textarea')}
            {renderField('supplementaryInfo', 'Supplementary Information', 'textarea')}
          </>
        )}

        {activeTab === 'references' && (
          <>
            {renderField('reference1Name', 'Reference 1 Name')}
            {renderField('reference1Position', 'Reference 1 Position')}
            {renderField('reference1Address', 'Reference 1 Address', 'textarea')}
            {renderField('reference1Tel', 'Reference 1 Tel. No.')}
            {renderField('reference2Name', 'Reference 2 Name')}
            {renderField('reference2Position', 'Reference 2 Position')}
            {renderField('reference2Address', 'Reference 2 Address', 'textarea')}
            {renderField('reference2Tel', 'Reference 2 Tel. No.')}
            {renderField('canApproachCurrentEmployer', 'Can we approach your current employer before an offer of employment is made?', 'checkbox')}
            {renderField('sourceOfApplication', 'Source of Application')}
            {renderField('declarationSigned', 'I declare that the information given in this form is complete and accurate', 'checkbox')}
            {renderField('declarationDate', 'Date of Declaration', 'date')}
          </>
        )}

        <div className="form-actions">
          <button type="submit">Submit Application</button>
        </div>
      </form>
    </div>
  );
}

export default EmploymentApplicationForm;
import React from 'react';
import { 
  Box, 
  Button, 
  Checkbox, 
  FormControlLabel, 
  FormGroup, 
  Grid, 
  TextField, 
  Typography 
} from '@mui/material';

const ReviewForm = ({ reviewData, handleChange, handleCheckboxChange, handleSubmit, isSubmitting, error }) => {
  return (
    <form onSubmit={handleSubmit} className="common-form">
      <Typography variant="h6" gutterBottom>Employee Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Employee Name"
            name="employeeName"
            value={reviewData.employeeName}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Job Title"
            name="jobTitle"
            value={reviewData.jobTitle}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Department"
            name="department"
            value={reviewData.department}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date of Engagement"
            name="dateOfEngagement"
            type="date"
            value={reviewData.dateOfEngagement}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Manager"
            name="manager"
            value={reviewData.manager}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date of Meeting"
            name="dateOfMeeting"
            type="date"
            value={reviewData.dateOfMeeting}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom>Current Performance</Typography>
      <TextField
        label="Objectives/Competencies"
        name="objectives"
        value={reviewData.objectives}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />
      <TextField
        label="Achievements"
        name="achievements"
        value={reviewData.achievements}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Typography variant="h6" gutterBottom>Development</Typography>
      <TextField
        label="Strengths"
        name="strengths"
        value={reviewData.strengths}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />
      <TextField
        label="Needs Improvement"
        name="needsImprovement"
        value={reviewData.needsImprovement}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />
      <TextField
        label="Specific Development Activities"
        name="developmentActivities"
        value={reviewData.developmentActivities}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Typography variant="h6" gutterBottom>Career Planning</Typography>
      <TextField
        label="Career Planning"
        name="careerPlanning"
        value={reviewData.careerPlanning}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Typography variant="h6" gutterBottom>Other Discussion Points</Typography>
      <TextField
        label="Other Discussion Points"
        name="otherDiscussionPoints"
        value={reviewData.otherDiscussionPoints}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
      />

      <Typography variant="h6" gutterBottom>Performance Assessment</Typography>
      <FormGroup>
        <Typography variant="subtitle1">Client Service:</Typography>
        <FormControlLabel
          control={<Checkbox checked={reviewData.clientService.includes('meetsTargets')} onChange={() => handleCheckboxChange('clientService', 'meetsTargets')} />}
          label="Consistently meets/exceeds/falls short of client satisfaction targets."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.clientService.includes('communicatesEffectively')} onChange={() => handleCheckboxChange('clientService', 'communicatesEffectively')} />}
          label="Effectively communicates with clients to understand their needs."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.clientService.includes('deliversHighQuality')} onChange={() => handleCheckboxChange('clientService', 'deliversHighQuality')} />}
          label="Delivers high-quality services within allotted timeframes."
        />
      </FormGroup>

      <FormGroup>
        <Typography variant="subtitle1">Retail Sales:</Typography>
        <FormControlLabel
          control={<Checkbox checked={reviewData.retailSales.includes('recommendsProducts')} onChange={() => handleCheckboxChange('retailSales', 'recommendsProducts')} />}
          label="Effectively recommends and sells salon products to clients."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.retailSales.includes('achievesSalesTargets')} onChange={() => handleCheckboxChange('retailSales', 'achievesSalesTargets')} />}
          label="Achieves or exceeds sales targets."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.retailSales.includes('providesProductKnowledge')} onChange={() => handleCheckboxChange('retailSales', 'providesProductKnowledge')} />}
          label="Provides valuable product knowledge and recommendations."
        />
      </FormGroup>

      <FormGroup>
        <Typography variant="subtitle1">Work Management:</Typography>
        <FormControlLabel
          control={<Checkbox checked={reviewData.workManagement.includes('managesWorkload')} onChange={() => handleCheckboxChange('workManagement', 'managesWorkload')} />}
          label="Effectively manages workload during busy periods."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.workManagement.includes('prioritizesTasks')} onChange={() => handleCheckboxChange('workManagement', 'prioritizesTasks')} />}
          label="Prioritizes tasks and ensures timely completion of services."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.workManagement.includes('meetsClientDemand')} onChange={() => handleCheckboxChange('workManagement', 'meetsClientDemand')} />}
          label="Demonstrates the ability to meet client demand, including walk-ins (if applicable)."
        />
      </FormGroup>

      <FormGroup>
        <Typography variant="subtitle1">Teamwork:</Typography>
        <FormControlLabel
          control={<Checkbox checked={reviewData.teamwork.includes('worksCooperatively')} onChange={() => handleCheckboxChange('teamwork', 'worksCooperatively')} />}
          label="Works cooperatively and collaboratively with colleagues."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.teamwork.includes('contributesPositively')} onChange={() => handleCheckboxChange('teamwork', 'contributesPositively')} />}
          label="Contributes positively to the salon environment."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.teamwork.includes('supportsTeam')} onChange={() => handleCheckboxChange('teamwork', 'supportsTeam')} />}
          label="Willing to help and support other team members."
        />
      </FormGroup>

      <FormGroup>
        <Typography variant="subtitle1">Attendance & Punctuality:</Typography>
        <FormControlLabel
          control={<Checkbox checked={reviewData.attendancePunctuality.includes('consistentAttendance')} onChange={() => handleCheckboxChange('attendancePunctuality', 'consistentAttendance')} />}
          label="Maintains a consistent record of attendance and punctuality."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.attendancePunctuality.includes('arrivesOnTime')} onChange={() => handleCheckboxChange('attendancePunctuality', 'arrivesOnTime')} />}
          label="Arrives on time prepared to work."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.attendancePunctuality.includes('communicatesAbsences')} onChange={() => handleCheckboxChange('attendancePunctuality', 'communicatesAbsences')} />}
          label="Communicates any absences or lateness proactively."
        />
      </FormGroup>

      <FormGroup>
        <Typography variant="subtitle1">Communication:</Typography>
        <FormControlLabel
          control={<Checkbox checked={reviewData.communication.includes('communicatesEffectively')} onChange={() => handleCheckboxChange('communication', 'communicatesEffectively')} />}
          label="Communicates effectively with clients and colleagues, both verbally and in writing."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.communication.includes('activelyListens')} onChange={() => handleCheckboxChange('communication', 'activelyListens')} />}
          label="Actively listens and understands client requests."
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.communication.includes('explainsServicesClearly')} onChange={() => handleCheckboxChange('communication', 'explainsServicesClearly')} />}
          label="Clearly explains services and recommendations."
        />
      </FormGroup>

      <Typography variant="h6" gutterBottom>Overall Performance Rating</Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={reviewData.overallPerformanceRating.includes('outstanding')} onChange={() => handleCheckboxChange('overallPerformanceRating', 'outstanding')} />}
          label="Outstanding"
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.overallPerformanceRating.includes('meetsExpectations')} onChange={() => handleCheckboxChange('overallPerformanceRating', 'meetsExpectations')} />}
          label="Meets Expectations"
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.overallPerformanceRating.includes('needsImprovement')} onChange={() => handleCheckboxChange('overallPerformanceRating', 'needsImprovement')} />}
          label="Needs Improvement"
        />
        <FormControlLabel
          control={<Checkbox checked={reviewData.overallPerformanceRating.includes('unsatisfactory')} onChange={() => handleCheckboxChange('overallPerformanceRating', 'unsatisfactory')} />}
          label="Unsatisfactory"
        />
      </FormGroup>

      <Typography variant="h6" gutterBottom>Supervisor's Comments</Typography>
      <TextField
        label="Supervisor's Comments"
        name="supervisorsComments"
        value={reviewData.supervisorsComments}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Typography variant="h6" gutterBottom>Employee Comments</Typography>
      <TextField
        label="Employee Comments"
        name="employeeComments"
        value={reviewData.employeeComments}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Typography variant="h6" gutterBottom>Action Plans</Typography>
      <TextField
        label="Action Plans"
        name="actionPlans"
        value={reviewData.actionPlans}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Typography variant="h6" gutterBottom>Signatures</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Employee Signature"
            name="employeeSignature"
            value={reviewData.employeeSignature}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Appraiser Signature"
            name="appraiserSignature"
            value={reviewData.appraiserSignature}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date"
            name="reviewDate"
            type="date"
            value={reviewData.reviewDate}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Reviewing Manager Signature (Optional)"
            name="reviewingManagerSignature"
            value={reviewData.reviewingManagerSignature}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date"
            name="reviewingManagerDate"
            type="date"
            value={reviewData.reviewingManagerDate}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3 }}>
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        <Button 
          variant="contained" 
          type="submit" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Box>
    </form>
  );
};

export default ReviewForm;
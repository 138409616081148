import React, { useState, useEffect, useCallback } from 'react';
import api from './axiosConfig';
import { 
  Box, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Button,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';

const DocumentApproval = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const showSnackbar = useCallback((message, severity) => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const fetchDocuments = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/rental_documents');
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
      if (error.response && error.response.status === 401) {
        showSnackbar('Unauthorized: Please log in again', 'error');
      } else {
        showSnackbar('Error fetching documents', 'error');
      }
    } finally {
      setLoading(false);
    }
  }, [showSnackbar]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleApproval = async (id, status) => {
    try {
      await api.put(`/rental_documents/${id}`, { status });
      showSnackbar(`Document ${status}`, 'success');
      fetchDocuments();
    } catch (error) {
      console.error('Error updating document status:', error);
      showSnackbar('Error updating document status', 'error');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        p: 3,
        mt: 3,
        maxWidth: 1200,
        mx: 'auto',
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 1
      }}
    >
      <Typography variant="h4" gutterBottom>Document Approval</Typography>
      {documents.length === 0 ? (
        <Typography>No pending documents for approval.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell>Upload Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((document) => (
                <TableRow key={document.id}>
                  <TableCell>{document.name}</TableCell>
                  <TableCell>{new Date(document.upload_date).toLocaleDateString()}</TableCell>
                  <TableCell>{document.status}</TableCell>
                  <TableCell>
                    <Box display="flex" gap={1}>
                      <Button 
                        onClick={() => handleApproval(document.id, 'accepted')} 
                        color="primary"
                        variant="contained"
                        size="small"
                        disabled={document.status === 'accepted'}
                      >
                        Accept
                      </Button>
                      <Button 
                        onClick={() => handleApproval(document.id, 'denied')} 
                        color="secondary"
                        variant="contained"
                        size="small"
                        disabled={document.status === 'denied'}
                      >
                        Deny
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DocumentApproval;
import React, { useState, useEffect } from 'react';
import { Paper, Typography, Snackbar, Alert } from '@mui/material';
import api from './axiosConfig';
import SearchEmployee from './SearchEmployee';
import ReviewForm from './ReviewForm';
import { useAuth } from './AuthContext';

const SubmitEmployeeReview = () => {
  const { user } = useAuth();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [reviewData, setReviewData] = useState({
    employeeName: '',
    jobTitle: '',
    department: '',
    dateOfEngagement: '',
    manager: '',
    dateOfMeeting: '',
    objectives: '',
    achievements: '',
    strengths: '',
    needsImprovement: '',
    developmentActivities: '',
    careerPlanning: '',
    otherDiscussionPoints: '',
    clientService: [],
    retailSales: [],
    workManagement: [],
    teamwork: [],
    attendancePunctuality: [],
    communication: [],
    overallPerformanceRating: [],
    supervisorsComments: '',
    employeeComments: '',
    actionPlans: '',
    employeeSignature: '',
    appraiserSignature: '',
    reviewDate: '',
    reviewingManagerSignature: '',
    reviewingManagerDate: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    console.log('SubmitEmployeeReview mounted');
    return () => console.log('SubmitEmployeeReview unmounted');
  }, []);

  const handleEmployeeSelect = (employeeId) => {
    console.log('Employee selected:', employeeId);
    setSelectedEmployeeId(employeeId);
    fetchReviewData(employeeId);
  };

  const fetchReviewData = async (employeeId) => {
    console.log('Fetching review data for employee:', employeeId);
    try {
      const response = await api.get(`/api/reviews/${employeeId}`);
      console.log('Fetched review data:', response.data);
      if (response.data) {
        setReviewData(response.data);
      } else {
        console.log('No existing review data found, initializing new review');
        setReviewData({
          ...reviewData,
          employeeId: employeeId,
          // You may want to fetch and set some basic employee info here
        });
      }
      setError(null);
    } catch (error) {
      console.error('Error fetching review data:', error);
      if (error.response && error.response.status === 404) {
        console.log('No existing review found, initializing new review');
        setReviewData({
          ...reviewData,
          employeeId: employeeId,
          // You may want to fetch and set some basic employee info here
        });
        setError(null);
      } else {
        setError('Failed to fetch review data. Please try again.');
        setSnackbar({ open: true, message: 'Failed to fetch review data', severity: 'error' });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReviewData({ ...reviewData, [name]: value });
  };

  const handleCheckboxChange = (section, value) => {
    setReviewData((prevData) => {
      const updatedSection = prevData[section].includes(value)
        ? prevData[section].filter((item) => item !== value)
        : [...prevData[section], value];
      return { ...prevData, [section]: updatedSection };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    try {
      const response = await api.post(`/api/reviews/employee/${selectedEmployeeId}`, reviewData);
      console.log('Review submitted successfully', response.data);
      setSnackbar({ open: true, message: 'Review submitted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error submitting review', error);
      setError('Failed to submit review. Please try again.');
      setSnackbar({ open: true, message: 'Failed to submit review', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Employee Review</Typography>
      {!selectedEmployeeId ? (
        <>
          <Typography variant="body1" gutterBottom>
            Please search and select an employee to review.
          </Typography>
          <SearchEmployee onEmployeeSelect={handleEmployeeSelect} />
        </>
      ) : (
        <ReviewForm 
          reviewData={reviewData}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          error={error}
        />
      )}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SubmitEmployeeReview;
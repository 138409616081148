// Disciplinary.js
import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';

const Disciplinary = ({ employee }) => {
  const [disciplinaryActions, setDisciplinaryActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newAction, setNewAction] = useState({
    type_of_action: '',
    reason: '',
    details_of_appeal: '',
    date_action_taken: '',
    expiry_date: ''
  });

  useEffect(() => {
    if (employee && employee.id) {
      fetchDisciplinaryActions(employee.id);
    }
  }, [employee]);

  const fetchDisciplinaryActions = async (employeeId) => {
    try {
      setLoading(true);
      const response = await api.get(`/api/disciplinary/employee/${employeeId}`);
      setDisciplinaryActions(response.data);
    } catch (err) {
      console.error('Error fetching disciplinary actions:', err);
      setError('Failed to fetch disciplinary actions');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewAction({
      type_of_action: '',
      reason: '',
      details_of_appeal: '',
      date_action_taken: '',
      expiry_date: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAction({ ...newAction, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post(`/api/disciplinary/employee/${employee.id}`, newAction);
      setDisciplinaryActions([...disciplinaryActions, response.data]);
      handleCloseDialog();
    } catch (err) {
      console.error('Error adding disciplinary action:', err);
      setError('Failed to add disciplinary action');
    }
  };

  if (loading) return <Typography>Loading disciplinary actions...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Add Disciplinary Action
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Appeal Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disciplinaryActions.map((action) => (
              <TableRow key={action.id}>
                <TableCell>{new Date(action.date_action_taken).toLocaleDateString()}</TableCell>
                <TableCell>{action.type_of_action}</TableCell>
                <TableCell>{action.reason}</TableCell>
                <TableCell>{action.expiry_date ? new Date(action.expiry_date).toLocaleDateString() : 'N/A'}</TableCell>
                <TableCell>{action.details_of_appeal || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add Disciplinary Action</DialogTitle>
        <DialogContent>
          <TextField
            name="type_of_action"
            label="Type of Action"
            fullWidth
            margin="normal"
            value={newAction.type_of_action}
            onChange={handleInputChange}
          />
          <TextField
            name="reason"
            label="Reason"
            fullWidth
            margin="normal"
            value={newAction.reason}
            onChange={handleInputChange}
          />
          <TextField
            name="details_of_appeal"
            label="Appeal Details"
            fullWidth
            margin="normal"
            value={newAction.details_of_appeal}
            onChange={handleInputChange}
          />
          <TextField
            name="date_action_taken"
            label="Date Action Taken"
            type="date"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={newAction.date_action_taken}
            onChange={handleInputChange}
          />
          <TextField
            name="expiry_date"
            label="Expiry Date"
            type="date"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={newAction.expiry_date}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Disciplinary;
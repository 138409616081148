import React from 'react';
import { 
  Grid, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  FormHelperText 
} from '@mui/material';

const PersonalInfo = ({ employee, isEditable, handleChange, errors, roleOptions }) => {
  if (!employee) {
    return <div>Loading employee information...</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={employee.name || ''}
          onChange={handleChange}
          disabled={!isEditable}
          error={!!errors.name}
          helperText={errors.name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={employee.email || ''}
          onChange={handleChange}
          disabled={!isEditable}
          error={!!errors.email}
          helperText={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth error={!!errors.role}>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            name="role"
            value={employee.role || ''}
            onChange={handleChange}
            disabled={!isEditable}
          >
            {roleOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Address Line 1"
          name="address_line1"
          value={employee.address_line1 || ''}
          onChange={handleChange}
          disabled={!isEditable}
          error={!!errors.address_line1}
          helperText={errors.address_line1}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Address Line 2"
          name="address_line2"
          value={employee.address_line2 || ''}
          onChange={handleChange}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Address Line 3"
          name="address_line3"
          value={employee.address_line3 || ''}
          onChange={handleChange}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Postcode"
          name="postcode"
          value={employee.postcode || ''}
          onChange={handleChange}
          disabled={!isEditable}
          error={!!errors.postcode}
          helperText={errors.postcode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Contact Number"
          name="contact_number"
          value={employee.contact_number || ''}
          onChange={handleChange}
          disabled={!isEditable}
          error={!!errors.contact_number}
          helperText={errors.contact_number}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Emergency Contact"
          name="emergency_contact"
          value={employee.emergency_contact || ''}
          onChange={handleChange}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Emergency Number"
          name="emergency_number"
          value={employee.emergency_number || ''}
          onChange={handleChange}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Date of Birth"
          name="date_of_birth"
          type="date"
          value={employee.date_of_birth || ''}
          onChange={handleChange}
          disabled={!isEditable}
          InputLabelProps={{ shrink: true }}
          error={!!errors.date_of_birth}
          helperText={errors.date_of_birth}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="National Insurance Number"
          name="national_insurance_number"
          value={employee.national_insurance_number || ''}
          onChange={handleChange}
          disabled={!isEditable}
          error={!!errors.national_insurance_number}
          helperText={errors.national_insurance_number}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Start Date"
          name="start_date"
          type="date"
          value={employee.start_date || ''}
          onChange={handleChange}
          disabled={!isEditable}
          InputLabelProps={{ shrink: true }}
          error={!!errors.start_date}
          helperText={errors.start_date}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Hourly Rate"
          name="hourly_rate"
          type="number"
          value={employee.hourly_rate || ''}
          onChange={handleChange}
          disabled={!isEditable}
          error={!!errors.hourly_rate}
          helperText={errors.hourly_rate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Commission % on Services"
          name="commission_services"
          type="number"
          value={employee.commission_services || ''}
          onChange={handleChange}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Commission % on Products"
          name="commission_products"
          type="number"
          value={employee.commission_products || ''}
          onChange={handleChange}
          disabled={!isEditable}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
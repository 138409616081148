import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';

const Holidays = ({ employee, isEditable }) => {
  console.log('Holidays component rendered', { employee, isEditable });

  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Holidays useEffect triggered', { employee });
    if (employee && employee.id) {
      console.log('Fetching holidays for employee', employee.id);
      fetchHolidays(employee.id);
    } else {
      console.log('No employee ID available');
      setLoading(false);
    }

    // Test API call
    const testApi = async () => {
      try {
        const response = await api.get('/api/test');
        console.log('Test API response:', response.data);
      } catch (error) {
        console.error('Test API error:', error.response?.data || error.message);
      }
    };
    testApi();
  }, [employee]);

  const fetchHolidays = async (employeeId) => {
    try {
      setLoading(true);
      console.log(`Making API call to fetch holidays for employee ${employeeId}`);
      const response = await api.get(`/api/holidays/employee/${employeeId}`);
      console.log('Holidays API response:', response.data);
      setHolidays(response.data);
    } catch (err) {
      console.error('Error fetching holidays:', err.response?.data || err.message);
      setError(`Failed to fetch holidays: ${err.response?.data?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (holidayId) => {
    try {
      console.log(`Approving holiday ${holidayId}`);
      await api.post(`/api/holidays/${holidayId}/approve`);
      fetchHolidays(employee.id);
    } catch (err) {
      console.error('Error approving holiday:', err.response?.data || err.message);
      setError(`Failed to approve holiday: ${err.response?.data?.message || err.message}`);
    }
  };

  const handleDeny = async (holidayId) => {
    try {
      console.log(`Denying holiday ${holidayId}`);
      await api.post(`/api/holidays/${holidayId}/deny`);
      fetchHolidays(employee.id);
    } catch (err) {
      console.error('Error denying holiday:', err.response?.data || err.message);
      setError(`Failed to deny holiday: ${err.response?.data?.message || err.message}`);
    }
  };

  if (loading) return <Typography>Loading holidays...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Days</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Notes</TableCell>
            {isEditable && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {holidays.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>No holidays found</TableCell>
            </TableRow>
          ) : (
            holidays.map((holiday) => (
              <TableRow key={holiday.id}>
                <TableCell>{new Date(holiday.start_date).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(holiday.end_date).toLocaleDateString()}</TableCell>
                <TableCell>{holiday.number_of_days_holiday}</TableCell>
                <TableCell>{holiday.status}</TableCell>
                <TableCell>{holiday.notes}</TableCell>
                {isEditable && holiday.status === 'PENDING' && (
                  <TableCell>
                    <Button onClick={() => handleApprove(holiday.id)}>Approve</Button>
                    <Button onClick={() => handleDeny(holiday.id)}>Deny</Button>
                  </TableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Holidays;
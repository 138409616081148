import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const Training = ({ employee }) => {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (employee && employee.id) {
      fetchTrainings(employee.id);
    }
  }, [employee]);

  const fetchTrainings = async (employeeId) => {
    try {
      setLoading(true);
      const response = await api.get(`/api/training/employee/${employeeId}`);
      setTrainings(response.data);
    } catch (err) {
      console.error('Error fetching trainings:', err);
      setError('Failed to fetch trainings');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Typography>Loading trainings...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Training Name</TableCell>
            <TableCell>Date Completed</TableCell>
            <TableCell>Expiry Date</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trainings.map((training) => (
            <TableRow key={training.id}>
              <TableCell>{training.training_name}</TableCell>
              <TableCell>{new Date(training.date_completed).toLocaleDateString()}</TableCell>
              <TableCell>{training.expiry_date ? new Date(training.expiry_date).toLocaleDateString() : 'N/A'}</TableCell>
              <TableCell>{training.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Training;
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.salonmanagementsystem.co.uk',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // This ensures cookies are sent with requests
});

api.interceptors.request.use(
  (config) => {
    console.log('Request being sent:', config.method.toUpperCase(), config.url);
    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    console.log('Response received:', response.status, response.config.url);
    return response;
  },
  async (error) => {
    console.error('Axios interceptor caught an error:', error);
    
    const originalRequest = error.config;
    
    if (error.response) {
      console.error('Response error:', error.response.data);
      console.error('Status code:', error.response.status);
      
      // Handle 401 Unauthorized
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        console.log('Attempting to refresh token');
        try {
          // Attempt to refresh the token
          const response = await api.post('/api/auth/refresh');
          
          if (response.data.success) {
            console.log('Token refreshed successfully');
            // If token refresh is successful, retry the original request
            return api(originalRequest);
          }
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          console.log('Redirecting to login page in 5 seconds');
          setTimeout(() => {
            window.location.href = '/login';
          }, 5000); // 5 seconds delay
          return Promise.reject(refreshError);
        }
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    
    // Check for CORS error
    if (error.message === 'Network Error') {
      console.error('Possible CORS error or network issue. Check server CORS configuration and network connection.');
    }
    
    return Promise.reject(error);
  }
);

export default api;
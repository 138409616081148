import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  TextField,
  Tab,
  Tabs,
  Snackbar,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import api from './axiosConfig';
import Expenditure from './Expenditure';

const validationSchema = Yup.object({
  type: Yup.string().required('Type is required'),
  supplier_name: Yup.string().required('Supplier name is required'),
  email: Yup.string().email('Invalid email format'),
  vat_number: Yup.string(),
  account_number: Yup.string(),
});

const SupplierCard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isNewSupplier, setIsNewSupplier] = useState(false);

  const formik = useFormik({
    initialValues: {
      type: '',
      supplier_name: '',
      category: '',
      description: '',
      vat_applicable: 'no',
      address: '',
      email: '',
      products_services: '',
      contact_number: '',
      vat_number: '',
      account_number: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(values, { setSubmitting }) {
    try {
      let response;
      if (isNewSupplier) {
        response = await api.post('/api/suppliers', values);
        setSnackbar({ open: true, message: 'New supplier added successfully', severity: 'success' });
      } else {
        response = await api.put(`/api/suppliers/${values.id}`, values);
        setSnackbar({ open: true, message: 'Supplier updated successfully', severity: 'success' });
      }
      setIsNewSupplier(false);
      formik.setValues(response.data);
    } catch (error) {
      console.error('Error saving supplier:', error);
      setSnackbar({ open: true, message: 'Error saving supplier', severity: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  const handleSearch = async () => {
    try {
      const response = await api.get(`/api/suppliers/search?name=${searchTerm}`);
      if (response.data && response.data.length > 0) {
        const supplierData = response.data[0];
        formik.setValues({
          id: supplierData.id,
          type: supplierData.type || '',
          supplier_name: supplierData.supplier_name || '',
          category: supplierData.category || '',
          description: supplierData.description || '',
          vat_applicable: supplierData.vat_applicable ? 'yes' : 'no',
          address: supplierData.address || '',
          email: supplierData.email || '',
          products_services: supplierData.products_services || '',
          contact_number: supplierData.contact_number || '',
          vat_number: supplierData.vat_number || '',
          account_number: supplierData.account_number || '',
        });
        setIsNewSupplier(false);
        setSnackbar({ open: true, message: 'Supplier found', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'No supplier found', severity: 'info' });
      }
    } catch (error) {
      console.error('Error searching supplier:', error);
      setSnackbar({ open: true, message: 'Error searching supplier', severity: 'error' });
    }
  };

  const resetForm = () => {
    formik.resetForm();
    setIsNewSupplier(true);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Supplier Card</Typography>
      
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Search supplier by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button fullWidth variant="contained" onClick={handleSearch}>Search</Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button fullWidth variant="outlined" onClick={resetForm}>New Supplier</Button>
        </Grid>
      </Grid>

      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} sx={{ mb: 3 }}>
        <Tab label="Supplier Info" />
        <Tab label="Expenditure" />
        <Tab label="Import" />
      </Tabs>

      <form onSubmit={formik.handleSubmit}>
        {activeTab === 0 && (
          <Grid container spacing={2}>
            {[
              { name: 'type', label: 'Type', required: true },
              { name: 'supplier_name', label: 'Supplier Name', required: true },
              { name: 'category', label: 'Category' },
              { name: 'description', label: 'Description' },
              { name: 'address', label: 'Address' },
              { name: 'email', label: 'Email', type: 'email' },
              { name: 'products_services', label: 'Products/Services' },
              { name: 'contact_number', label: 'Contact Number' },
              { name: 'vat_number', label: 'VAT Number' },
              { name: 'account_number', label: 'Account Number' }
            ].map((field) => (
              <Grid item xs={12} sm={6} key={field.name}>
                <TextField
                  fullWidth
                  id={field.name}
                  name={field.name}
                  label={field.label}
                  type={field.type || 'text'}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                  helperText={formik.touched[field.name] && formik.errors[field.name]}
                  required={field.required}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <RadioGroup
                row
                name="vat_applicable"
                value={formik.values.vat_applicable}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="VAT Applicable" />
                <FormControlLabel value="no" control={<Radio />} label="VAT Not Applicable" />
              </RadioGroup>
            </Grid>
          </Grid>
        )}
        {activeTab === 1 && (
          <Expenditure formik={formik} />
        )}
        {activeTab === 2 && (
          <Typography>Import tab content goes here</Typography>
        )}

        <Box sx={{ mt: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            {isNewSupplier ? 'Add Supplier' : 'Update Supplier'}
          </Button>
        </Box>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SupplierCard;
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import api from './axiosConfig';
import * as Yup from 'yup';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormGroup
} from '@mui/material';
import PersonalInfo from './PersonalInfo';
import Holidays from './Holidays';
import Absences from './Absences';
import Training from './Training';
import Disciplinary from './Disciplinary';
import Notes from './Notes';

const initialEmployeeState = {
  name: '',
  email: '',
  role: '',
  hourly_rate: '',
  address_line1: '',
  address_line2: '',
  address_line3: '',
  postcode: '',
  contact_number: '',
  emergency_contact: '',
  emergency_number: '',
  date_of_birth: '',
  national_insurance_number: '',
  start_date: '',
  active: true,
  commission_services: '',
  commission_products: '',
  full_time_equivalent: 1
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  role: Yup.string().required('Role is required'),
  hourly_rate: Yup.number().positive('Hourly rate must be positive').required('Hourly rate is required'),
  address_line1: Yup.string().required('Address line 1 is required'),
  postcode: Yup.string().required('Postcode is required'),
  contact_number: Yup.string().required('Contact number is required'),
  date_of_birth: Yup.date().required('Date of birth is required'),
  national_insurance_number: Yup.string().required('National Insurance number is required'),
  start_date: Yup.date().required('Start date is required'),
});

const roleOptions = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'ACCOUNT_OWNER', label: 'Account Owner' },
  { value: 'ACCOUNT_MANAGER', label: 'Account Manager' },
  { value: 'ACCOUNT_USER', label: 'Account User' }
];

const EmployeeCard = () => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(initialEmployeeState);
  const [originalEmployee, setOriginalEmployee] = useState({});
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState('View');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [activeTab, setActiveTab] = useState('PersonalInfo');
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);

  const fetchEmployeeDetails = useCallback(async (employeeId) => {
    console.log('Fetching employee details...', employeeId);
    setIsLoading(true);
    try {
      const response = await api.get(`/api/employees/${employeeId}`);
      console.log('Employee details response:', response.data);
      setSelectedEmployee(response.data || initialEmployeeState);
      setOriginalEmployee(response.data || {});
    } catch (err) {
      console.error('Error fetching employee details:', err);
      setSnackbar({
        open: true,
        message: 'Failed to fetch employee details: ' + (err.response?.data?.error || err.message || 'Unknown error'),
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchReviews = useCallback(async (employeeId) => {
    try {
      const response = await api.get(`/api/reviews/employee/${employeeId}`);
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
      setReviews([]);
    }
  }, []);

  useEffect(() => {
    if (selectedEmployee?.id) {
      fetchReviews(selectedEmployee.id);
    }
  }, [selectedEmployee, fetchReviews]);

  useEffect(() => {
    console.log('EmployeeCard component mounted');
    return () => {
      console.log('EmployeeCard component unmounted');
    };
  }, []);

  useEffect(() => {
    console.log('useEffect triggered - selectedEmployee:', selectedEmployee?.id, 'mode:', mode);
    if (selectedEmployee?.id && mode === 'View') {
      fetchEmployeeDetails(selectedEmployee.id);
    } else {
      setIsLoading(false);
    }
  }, [selectedEmployee?.id, mode, fetchEmployeeDetails]);

  const handleSearch = async (event) => {
    event.preventDefault();
    setErrors({});
    console.log('Searching for employee:', searchTerm);
    
    if (!searchTerm.trim()) {
      setErrors({ search: 'Please enter an employee name to search.' });
      return;
    }
    
    setIsLoading(true);
    try {
      const response = await api.get('/api/employees/search', {
        params: { query: searchTerm }
      });
      console.log('Search response:', response.data);
      
      if (response.data.error) {
        setErrors({ search: response.data.error });
        setSelectedEmployee(initialEmployeeState);
      } else if (response.data.employees && response.data.employees.length > 0) {
        setSelectedEmployee(response.data.employees[0] || initialEmployeeState);
        setMode('View');
        setSnackbar({ open: true, message: 'Employee found.', severity: 'success' });
      } else {
        setSelectedEmployee(initialEmployeeState);
        setSnackbar({ open: true, message: 'No employee found.', severity: 'info' });
      }
    } catch (err) {
      console.error('Error searching employee:', err);
      setErrors({ search: 'An error occurred while fetching employee data.' });
      setSelectedEmployee(initialEmployeeState);
      setSnackbar({
        open: true,
        message: 'Error searching employee: ' + (err.response?.data?.error || err.message || 'Unknown error'),
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    console.log('Save button clicked');
    console.log('Current mode:', mode);
    console.log('Selected employee:', selectedEmployee);
    console.log('User role:', user?.role);

    setErrors({});
    try {
      await validationSchema.validate(selectedEmployee, { abortEarly: false });
      
      const personalInfoFields = [
        'name', 'email', 'role', 'hourly_rate', 'address_line1', 'address_line2',
        'address_line3', 'postcode', 'contact_number', 'emergency_contact',
        'emergency_number', 'date_of_birth', 'national_insurance_number',
        'start_date', 'active', 'commission_services', 'commission_products',
        'full_time_equivalent'
      ];
      
      const updatedData = Object.fromEntries(
        Object.entries(selectedEmployee).filter(([key]) => personalInfoFields.includes(key))
      );

      let response;
      if (mode === 'New') {
        console.log('Creating new employee...');
        response = await api.post('/api/employees', updatedData);
      } else if (mode === 'Update' && selectedEmployee?.id) {
        console.log('Updating employee...', selectedEmployee.id);
        response = await api.put(`/api/employees/${selectedEmployee.id}`, updatedData);
      } else {
        throw new Error('Invalid mode or missing employee ID for update');
      }

      console.log('API response:', response);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      setSelectedEmployee(response.data.employee || initialEmployeeState);
      setOriginalEmployee(response.data.employee || {});
      setMode('View');
      setSnackbar({ 
        open: true, 
        message: mode === 'New' ? 'New employee created successfully' : 'Employee data updated successfully', 
        severity: 'success'
      });
    } catch (error) {
      console.error('Error in handleSave:', error);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.error('API Error:', error.response?.data);
        setSnackbar({ 
          open: true, 
          message: `Error: ${error.response?.data?.error || error.message || 'Unknown error'}`,
          severity: 'error' 
        });
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedEmployee(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleModeChange = (newMode) => {
    console.log('Mode changed to:', newMode);
    if (newMode === 'New') {
      setSelectedEmployee(initialEmployeeState);
    } else if (newMode === 'View') {
      setSelectedEmployee({ ...originalEmployee });
    }
    setMode(newMode);
    setErrors({});
  };

  const handleActiveStatusChange = (event) => {
    setSelectedEmployee(prev => ({ ...prev, active: event.target.value === 'active' }));
  };

  const handleViewFullReview = (review) => {
    setSelectedReview(review);
    setOpenReviewDialog(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      : 'Invalid Date';
  };

  const renderReviewDetail = (label, value) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2">{label}:</Typography>
      <Typography>{value || 'N/A'}</Typography>
    </Box>
  );

  const renderCheckboxGroup = (title, items) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2">{title}</Typography>
      <FormGroup>
        {items.map((item, index) => (
          <FormControlLabel
            key={index}
            control={<Checkbox checked={item.value === 1} disabled />}
            label={item.label}
          />
        ))}
      </FormGroup>
    </Box>
  );

  const isEditable = mode === 'New' || mode === 'Update';
  const canEditEmployee = user?.role === 'ACCOUNT_OWNER' || user?.role === 'ACCOUNT_MANAGER';

  const isSaveButtonEnabled = () => {
    if (mode === 'New') {
      return canEditEmployee;
    } else if (mode === 'Update') {
      return isEditable && canEditEmployee;
    }
    return false;
  };

  const renderTabContent = () => {
    console.log('Rendering tab content', { activeTab, selectedEmployee });
    
    if (!selectedEmployee?.id && activeTab !== 'PersonalInfo' && mode !== 'New') {
      return <Typography>Please select an employee first.</Typography>;
    }

    switch (activeTab) {
      case 'PersonalInfo':
        return (
          <PersonalInfo 
            employee={selectedEmployee} 
            isEditable={isEditable} 
            handleChange={handleChange}
            errors={errors}
            roleOptions={roleOptions}
          />
        );
      case 'Holidays':
        return selectedEmployee?.id ? <Holidays employee={selectedEmployee} isEditable={isEditable} /> : null;
      case 'Absences':
        return selectedEmployee?.id ? <Absences employee={selectedEmployee} /> : null;
      case 'Training':
        return selectedEmployee?.id ? <Training employee={selectedEmployee} isEditable={isEditable} /> : null;
      case 'Reviews':
        return (
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Review Date</TableCell>
                    <TableCell>Manager</TableCell>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Performance Score</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviews.map((review) => (
                    <TableRow key={review.id}>
                      <TableCell>{formatDate(review.date_of_meeting)}</TableCell>
                      <TableCell>{review.manager}</TableCell>
                      <TableCell>{review.job_title}</TableCell>
                      <TableCell>{review.performance_score}</TableCell>
                      <TableCell>
                        <Button variant="outlined" onClick={() => handleViewFullReview(review)}>
                          View Full Review
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      case 'Disciplinary':
        return selectedEmployee?.id ? <Disciplinary employee={selectedEmployee} isEditable={isEditable} /> : null;
      case 'Notes':
        return selectedEmployee?.id ? <Notes employee={selectedEmployee} isEditable={isEditable} /> : null;
      default:
        return null;
    }
  };

 return (
  <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
    <Typography variant="h4" gutterBottom>Employee Card</Typography>
    
    <Box sx={{ mb: 3 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Search employee by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            error={!!errors.search}
            helperText={errors.search}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button fullWidth variant="contained" onClick={handleSearch}>Search</Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Mode</InputLabel>
            <Select
              value={mode}
              onChange={(e) => handleModeChange(e.target.value)}
              label="Mode"
            >
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="View">View</MenuItem>
              <MenuItem value="Update">Update</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>

    <Box sx={{ mb: 3 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Employee Status</FormLabel>
        <RadioGroup
          row
          name="active-status"
          value={selectedEmployee.active ? 'active' : 'inactive'}
          onChange={handleActiveStatusChange}
        >
          <FormControlLabel value="active" control={<Radio />} label="Active" disabled={!isEditable} />
          <FormControlLabel value="inactive" control={<Radio />} label="Inactive" disabled={!isEditable} />
        </RadioGroup>
      </FormControl>
    </Box>

    <Box sx={{ mb: 3 }}>
      <Grid container spacing={1}>
        {['PersonalInfo', 'Holidays', 'Absences', 'Training', 'Reviews', 'Disciplinary', 'Notes'].map(tab => (
          <Grid item key={tab}>
            <Button
              size="small"
              variant={activeTab === tab ? 'contained' : 'outlined'}
              onClick={() => setActiveTab(tab)}
            >
              {tab === 'PersonalInfo' ? 'Personal Info' : tab}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>

    <Box sx={{ mb: 3, opacity: selectedEmployee.active === false ? 0.5 : 1 }}>
      {renderTabContent()}
    </Box>

    <Box>
      <Button 
        variant="contained" 
        onClick={handleSave} 
        disabled={!isSaveButtonEnabled()}
      >
        {mode === 'New' ? 'Create Employee' : 'Save Changes'}
      </Button>
      {!isSaveButtonEnabled() && (
        <Typography color="error" sx={{ mt: 1 }}>
          {!isEditable ? "Not in editable mode" : "User doesn't have permission to edit"}
        </Typography>
      )}
    </Box>

    {isLoading && (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <CircularProgress />
      </Box>
    )}

    <Snackbar 
      open={snackbar.open} 
      autoHideDuration={6000} 
      onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
    >
      <Alert 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
        severity={snackbar.severity}
        sx={{ width: '100%' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Dialog open={openReviewDialog} onClose={() => setOpenReviewDialog(false)} maxWidth="md" fullWidth>
      <DialogTitle>Full Review Details</DialogTitle>
      <DialogContent>
        {selectedReview && (
          <Box>
            {renderReviewDetail('Employee Name', selectedReview.employee_name)}
            {renderReviewDetail('Job Title', selectedReview.job_title)}
            {renderReviewDetail('Department', selectedReview.department)}
            {renderReviewDetail('Date of Engagement', formatDate(selectedReview.date_of_engagement))}
            {renderReviewDetail('Manager', selectedReview.manager)}
            {renderReviewDetail('Date of Meeting', formatDate(selectedReview.date_of_meeting))}
            {renderReviewDetail('Objectives', selectedReview.objectives)}
            {renderReviewDetail('Strengths', selectedReview.strengths)}
            {renderReviewDetail('Needs Improvement', selectedReview.needs_improvement)}
            {renderReviewDetail('Development Activities', selectedReview.development_activities)}
            {renderReviewDetail('Career Planning', selectedReview.career_planning)}
            {renderReviewDetail('Other Discussion Points', selectedReview.other_discussion_points)}
            {renderReviewDetail('Supervisor Comments', selectedReview.supervisor_comments)}
            {renderReviewDetail('Employee Comments', selectedReview.employee_comments)}
            {renderReviewDetail('Action Plans', selectedReview.action_plans)}
            {renderReviewDetail('Performance Score', selectedReview.performance_score)}
            {renderReviewDetail('Comments', selectedReview.comments)}
            {renderReviewDetail('Status', selectedReview.status)}
            
            {renderCheckboxGroup('Client Service', [
              { label: 'Meets Targets', value: selectedReview.client_service_meets_targets },
              { label: 'Communicates Effectively', value: selectedReview.client_service_communicates_effectively },
              { label: 'High Quality Service', value: selectedReview.client_service_high_quality_service }
            ])}
            
            {renderCheckboxGroup('Retail Sales', [
              { label: 'Recommends Products', value: selectedReview.retail_sales_recommends_products },
              { label: 'Meets Sales Targets', value: selectedReview.retail_sales_meets_sales_targets },
              { label: 'Product Knowledge', value: selectedReview.retail_sales_product_knowledge }
            ])}
            
            {renderCheckboxGroup('Work Management', [
              { label: 'Manages Workload', value: selectedReview.work_management_manages_workload },
              { label: 'Prioritizes Tasks', value: selectedReview.work_management_prioritizes_tasks },
              { label: 'Meets Client Demand', value: selectedReview.work_management_meets_client_demand }
            ])}
            
            {renderCheckboxGroup('Teamwork', [
              { label: 'Works Cooperatively', value: selectedReview.teamwork_works_cooperatively },
              { label: 'Positive Contribution', value: selectedReview.teamwork_positive_contribution },
              { label: 'Supports Team', value: selectedReview.teamwork_supports_team }
            ])}
            
            {renderCheckboxGroup('Attendance', [
              { label: 'Consistent Attendance', value: selectedReview.attendance_consistent_attendance },
              { label: 'On Time', value: selectedReview.attendance_on_time },
              { label: 'Communicates Absences', value: selectedReview.attendance_communicates_absences }
            ])}
            
            {renderCheckboxGroup('Communication', [
              { label: 'Effective Communication', value: selectedReview.communication_effective_communication },
              { label: 'Active Listening', value: selectedReview.communication_active_listening },
              { label: 'Clear Explanation', value: selectedReview.communication_clear_explanation }
            ])}
            
            {renderCheckboxGroup('Overall Performance', [
              { label: 'Outstanding', value: selectedReview.overall_performance_outstanding },
              { label: 'Meets Expectations', value: selectedReview.overall_performance_meets_expectations },
              { label: 'Needs Improvement', value: selectedReview.overall_performance_needs_improvement },
              { label: 'Unsatisfactory', value: selectedReview.overall_performance_unsatisfactory }
            ])}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenReviewDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
   </Paper>
  );
};

export default EmployeeCard;
import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import api from './axiosConfig';

const SearchEmployee = ({ onEmployeeSelect }) => {
  const [query, setQuery] = useState('');
  const [employees, setEmployees] = useState([]);
  const [searchError, setSearchError] = useState(null);

  const handleSearch = async () => {
    console.log('Initiating search with query:', query);
    try {
      const response = await api.get(`/api/reviews/search?query=${encodeURIComponent(query)}`);
      console.log('Search response:', response.data);
      setEmployees(response.data);
      setSearchError(null);
    } catch (error) {
      console.error('Error searching employees:', error.response ? error.response.data : error.message);
      setSearchError('Failed to search employees. Please try again.');
      setEmployees([]);
    }
  };

  return (
    <div>
      <TextField
        label="Search Employee"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" onClick={handleSearch}>Search</Button>
      {searchError && <Typography color="error">{searchError}</Typography>}
      {employees.length === 0 && !searchError && (
        <Typography>No employees found. Try a different search term.</Typography>
      )}
      <List>
        {employees.map(emp => (
          <ListItem key={emp.id} button onClick={() => {
            console.log('Employee selected from list:', emp.id);
            onEmployeeSelect(emp.id);
          }}>
            <ListItemText primary={emp.name} secondary={emp.job_title} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SearchEmployee;
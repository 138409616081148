import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Unauthorized = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Unauthorized
        </Typography>
        <Typography variant="body1" color="textSecondary">
          You do not have permission to view this page.
        </Typography>
      </Box>
    </Container>
  );
};

export default Unauthorized;
